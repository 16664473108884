<template>
  <div class="intro-y flex items-center justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست سوالات متداول</h1>
    <VButton class="!w-auto" @click="FAQModal = true;  editFAQ = {}">سوال جدید</VButton>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="this.filter.page"
      :pages="FAQs.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent mt-4 text-slate-600" no-padding scrollable>
    <div ref="table"></div>
  </VCard>

  <CreateFAQModal
      v-model="FAQModal"
      :editFAQ="editFAQ"
      @created="created"
      @updated="updated"/>

  <!-- Confirm Delete Modal -->
  <ConfirmModal
      v-model="confirmDeleteModal"
      text="آیا از حذف این سوال مطمئن هستید ؟"
      @confirm="deleteFAQ"
      @cancel="confirmDeleteModal = false"/>

</template>

<script>
import VCard from "@/components/General/VCard";
import VButton from "@/components/General/VButton";
import {deleteFAQ, getFAQs} from "@/API/AdminAPI";
import VPagination from "@hennge/vue3-pagination";
import "@/assets/css/pagination.css";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import CreateFAQModal from "@/components/Modal/Admin/CreateFAQModal.vue";
import VInput from "@/components/General/VInput.vue";

export default {
  name: "BrandPage",
  components: {
    VInput,
    CreateFAQModal,
    ConfirmModal,
    VButton,
    VCard,
    VPagination,
  },
  data() {
    return {
      FAQs: [],
      FAQModal: false,
      editFAQ: {},
      showFilter: true,

      filter: {
        page: 1,
        q: ''
      },

      confirmDeleteModal: false,
      deleteIndex: '',
    }
  },
  created() {
    this.getFAQs()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Edit Buttons
      let editButtons = document.querySelectorAll("div[data-btn-type='edit']")
      editButtons.forEach(element => {
        element.onclick = () => {
          this.editFAQ = this.FAQs.Faqs.find(FAQ => FAQ.FAQId == element.dataset.id)
          this.FAQModal = true
        }
      })
      // Delete Buttons
      let deleteButtons = document.querySelectorAll("div[data-btn-type='delete']")
      deleteButtons.forEach(element => {
        element.onclick = () => {
          this.deleteIndex = this.FAQs.Faqs.findIndex(FAQ => FAQ.FAQId == element.dataset.id)
          this.confirmDeleteModal = true
        }
      })
    });
  },
  methods: {
    async getFAQs() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.FAQs = (await getFAQs(this.filter)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    created(FAQ) {
      this.FAQModal = false
      this.FAQs.Faqs.unshift(FAQ)
      this.refreshTable()
    },
    updated(editedFAQ) {
      this.FAQModal = false
      this.editFAQ = false
      let index = this.FAQs.Faqs.findIndex(FAQ => FAQ.FAQId === editedFAQ.FAQId)
      this.FAQs.Faqs[index] = editedFAQ
      this.refreshTable()
    },
    deleteFAQ() {
      this.confirmDeleteModal = false
      deleteFAQ(this.FAQs.Faqs[this.deleteIndex].FAQId)
          .then(resp => {
            this.$infoModal.value = resp.data.messsage
            this.FAQs.Faqs.splice(this.deleteIndex, 1)
            this.refreshTable()
          })
    },
    refreshTable() {
      this.tabulator.setData(this.FAQs.Faqs)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'سوال',
            field: 'Question',
            formatter: cell => `<div class="whitespace-pre-wrap">${cell.getValue()}</div>`
          },
          {
            title: 'جواب',
            field: 'Answer',
            formatter: cell => `<div class="whitespace-pre-wrap">${cell.getValue()}</div>`
          },
          {
            title: 'دسته بندی',
            field: 'Category',
            formatter: cell => `<div class="whitespace-pre-wrap">${cell.getValue()}</div>`

          },
          {
            title: 'عملیات',
            field: 'FAQId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex">
                        <div class="flex items-center text-amber-500 cursor-pointer" data-btn-type="edit" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                             <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                            <span class="mr-1 text-xs">ویرایش</span>
                        </div>
                        <div class="flex items-center text-red-500 mr-4 cursor-pointer" data-btn-type="delete" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                             <span class="mr-1 text-xs">حذف</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getFAQs()
      }
    }
  }
}
</script>

<style scoped>

</style>