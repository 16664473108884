<template>
  <ModalView title="رد سفارش" class="text-gray-500" @opened="getRejectReasons">

    <form @submit.prevent="reject">

      <div class="mt-4 text-justify text-red-800" v-if="type === 'distributor' || type === 'inventory-carrier'">
        توجه: رد سفارش به دلایل جزئی توصیه نمی شود و بهتر است با تعامل شفاهی، سریعا حل و سپس تأیید شود تا برنامه ریزی
        مجدد لازم نداشته باشد. لذا آیا از رد این سفارش مطمئن هستید؟
      </div>

      <Multiselect
          v-if="type === 'distributor' || type === 'supporter' || type === 'inventory-carrier'"
          dir="rtl"
          class="mt-4 border-primary"
          v-model="stockDetailReject.rejectReasonLId"
          :options="rejectReasons"
          :searchable="true"
          placeholder="دلیل رد"
          required/>

      <VInput class="mt-4" placeholder="توضیحات" v-model="stockDetailReject.rejectReason" required/>

      <div class="mt-4" v-if="type === 'supporter'">
        <div class="flex items-center justify-between">
          <label for="transport">نیاز به جابجایی</label>
          <input id="transport" type="checkbox" v-model="stockDetailReject.needTransport">
        </div>

        <VInput class="mt-4" v-if="stockDetailReject.needTransport" v-model="stockDetailReject.transportDescription"
                placeholder="توضیحات جابجایی"/>
      </div>


      <div class="flex gap-x-4 mt-4">
        <VButton class="hidden" outline @click="$emit('cancel')">لغو</VButton>
        <VButton type="submit">ثبت</VButton>
      </div>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {getLookupCombos, rejectInvoice, supporterCancelInvoice} from "@/API/API";
import Multiselect from "@vueform/multiselect";

export default {
  name: "InvoiceRejectModal",
  props: ['invoice', 'type'],
  emits: ['rejected', 'cancel'],
  components: {VButton, VInput, ModalView, Multiselect},
  data() {
    return {
      rejectReasons: [],

      stockDetailReject: {
        rejectReasonLId: '',
        rejectReason: '',
        needTransport: false,
        transportDescription: '',
        stockDetails: []
      },

    }
  },
  methods: {
    async getRejectReasons() {
      if (this.rejectReasons.length !== 0)
        return

      if (this.type === 'distributor' || this.type === 'supporter' || this.type === 'inventory-carrier')
        this.rejectReasons = (await getLookupCombos(this.getSystemMenuCode())).data.data.Lookups.DenyReasonStates.map(reason => ({
          label: reason.Name,
          value: reason.LookupId
        }))
    },
    reject() {
      this.invoice.Items.forEach(item => item.StockDetails?.forEach(stockDetail => {
        if (stockDetail.RejectAmount) {
          let rejectedStockDetail = this.$helper.filterObject(stockDetail, ['StockDetailId', 'RejectAmount', 'RejectReasonLId'])
          this.stockDetailReject.stockDetails.push(rejectedStockDetail)
        }
      }))

      if (this.type === 'supporter')
        supporterCancelInvoice(this.invoice.InvoiceId, this.stockDetailReject)
            .then(resp => {
              this.$emit('rejected')
              this.$infoModal.value = resp.data.message
              this.$router.replace({name: 'SupporterInvoiceListAll'})
            })
      else
        rejectInvoice(this.invoice.InvoiceId, this.stockDetailReject)
            .then(resp => {
              this.$emit('rejected')
              this.$infoModal.value = resp.data.message
              if (this.type === 'inventory-packer') {
                this.$router.replace({name: 'InventoryPackerInvoiceList'})
              } else if (this.type === 'inventory-controller') {
                this.$router.replace({name: 'InventoryControllerInvoiceList'})
              } else if (this.type === 'distributor') {
                this.$router.replace({name: 'DistributorInvoiceListInventory'})
              }
            })
    },
    getSystemMenuCode() {
      switch (this.type) {
        case 'distributor':
          return 103070101
        case 'supporter':
          return 103080101
        case 'inventory-carrier':
          return 103100201
      }
    }
  }
}
</script>

<style scoped>

</style>