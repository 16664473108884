<template>
  <div class="grid grid-cols-4 gap-4 print:hidden">

    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">
      
      <div v-if="invoice.Acceptable">
        <VButton class="intro-y" @click="accept" >قبول سفارش
        </VButton>
        
      </div>
      <div class="intro-y" v-else-if="canEdit">
        <VButton @click="back" outline>بازگشت به پنل سفارش سازها</VButton>
      </div>
      <div class="flex mt-4">
          <VButton class="bg-blue-700 hidden lg:block" @click="printQR(true)">پرینت QR</VButton>
          <VButton class="bg-blue-700 hidden lg:block" @click="printInvoice">پرینت فاکتور</VButton>
        </div>
      <InvoiceDetailCard class="mt-4" :invoice="invoice"/>

    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <!-- Products -->
      <h1 class="intro-y mb-4 text-lg">محصولات</h1>
      <InvoiceProductCard
          v-for="item in invoice.Items"
          :key="item.InvoiceDetailId"
          :invoiceDetail="item"
          :canEdit="canEdit"
          @rejected="rejected"
          @replaced="getInvoice"
          @scanned="scanned"
          @resetReject="resetReject"
          type="inventory-packer"/>

      <div class="intro-y flex mt-4 gap-x-4 mr-auto lg:w-1/2 xl:w-1/3" v-if="canEdit">
        <VButton outline @click="rejectModal = true">رد</VButton>
        <VButton @click="proceed(true)" :disabled="hasRejectedStock">ثبت</VButton>
      </div>

    </div>
  </div>

  <InvoiceRejectModal
      v-model="rejectModal"
      :invoice="invoice"
      @rejected="invoiceRejected"
      @cancel="rejectModal = false"
      type="inventory-packer"/>

  <ConfirmInvoiceModal
      v-model="confirmProceedModal"
      :invoice="invoice"
      @submitted="invoiceSubmitted"
      type="inventory-packer"/>

  <InvoicePrint
      :invoice="invoice"
      v-if="invoice.InvoiceId && invoicePrintModal"/>

  <InvoiceQRPrint
      :invoiceId="invoice.InvoiceId"
      v-if="invoice.InvoiceId && invoiceQRPrintModal"/>

  <ConfirmModal
      v-model="confirmQRModal"
      title="تایید پرینت"
      text="برای این سفارش قبلا پرینت گرفته شده است، آیا از پرینت مجدد مطمئن هستید ؟ "
      @confirm="printQR(false)"
      @cancel="confirmQRModal = false"/>

</template>

<script>
import VButton from "@/components/General/VButton";
import InvoiceProductCard from "@/components/Invoice/InvoiceProductCard";
import {acceptInvoice, backInvoice, getInvoice} from "@/API/API";
import InvoiceRejectModal from "@/components/Modal/InvoiceRejectModal";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import {mapState} from "vuex";
import InvoicePrint from "@/components/Modal/InventoryPacker/InvoicePrint";
import InvoiceQRPrint from "@/components/Modal/InventoryPacker/InvoiceQRPrint";
import InvoiceDetailCard from "@/components/Invoice/InfoCard/InvoiceDetailCard";
import ConfirmInvoiceModal from "@/components/Modal/ConfirmInvoiceModal";

export default {
  name: "InvoiceDetail",
  components: {
    ConfirmInvoiceModal,
    InvoiceDetailCard,
    InvoiceQRPrint,
    InvoicePrint,
    ConfirmModal,
    InvoiceRejectModal,
    InvoiceProductCard,
    VButton,
  },
  data() {
    return {
      invoice: {},

      rejectModal: false,
      confirmProceedModal: false,
      invoicePrintModal: false,
      invoiceQRPrintModal: false,
      confirmQRModal: false,

      factorPrinted: false,
      QRPrinted: false,
    }
  },
  computed: {
    ...mapState(['user']),
    canEdit() {
      return this.invoice.StaffId === this.user.UserId
    },
    hasAnyScannedProduct() {
      return this.invoice.Items.some(item => item.StockDetails.some(stockDetail => stockDetail.ScanDate))
    },
    hasUnScannedProduct() {
      return this.invoice.Items.some(item => item.StockDetails.some(stockDetail => !stockDetail.ScanDate))
    },
    hasRejectedStock() {
      return this.invoice?.Items?.some(item => item.StockDetails != null && item.StockDetails.some(stockDetail => stockDetail.RejectAmount > 0))
    }
  },
  created() {
    this.getInvoice()
  },
  methods: {
    async getInvoice() {
      this.invoice = (await getInvoice(this.$route.params.id)).data.data
    },
    accept() {
      acceptInvoice(this.invoice.InvoiceId)
          .then(resp => 
          Object.assign(this.invoice, resp.data.data));

          //برداشتن دکمه اسکن شده برای ردیف های برگشت شده پس از قبول سفارش 
          this.invoice.Items.forEach(item => item.StockDetails.forEach(stockDetail => {
            if (stockDetail.RejectReason != null)
              stockDetail.ScanDate = null;
      }))
    },
    cancel(){console.log('IN cancel');
      this.rejectModal = false;
    },
    back() {
      backInvoice(this.invoice.InvoiceId)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$router.back()
          })
    },
    rejected(stockDetailReject) {
      // Add Log to StockDetail, so we can show it to user in StockDetailRejectModal
      this.invoice.Items.forEach(item => item.StockDetails.forEach(stockDetail => {
        if (stockDetail.StockDetailId === stockDetailReject.StockDetailId) {
          stockDetail.RejectAmount = stockDetailReject.RejectAmount
          stockDetail.RejectReasonLId = stockDetailReject.RejectReasonLId
        }
      }))
    },
    resetReject(stockDetailReject) {
      this.invoice.Items.forEach(item => item.StockDetails.forEach(stockDetail => {
        if (stockDetail.StockDetailId === stockDetailReject.StockDetailId) {
          stockDetail.RejectAmount = ''
          stockDetail.RejectReasonLId = ''
        }
      }))
    },
    scanned(stockDetailId) {
      this.invoice.Items.forEach(item => item.StockDetails.forEach(stockDetail => {
        if (stockDetail.StockDetailId === stockDetailId)
          stockDetail.ScanDate = 1
      }))
    },
    proceed() {
      // Check to see if all Products are Scanned
      if (this.hasUnScannedProduct) {
        this.confirmProceedModal = false
        this.$errorModal.value = 'کالا های اسکن نشده وجود دارد.'
        return
      }

      if (!this.QRPrinted && !this.hasAnyScannedProduct) {
        this.$errorModal.value = 'لطفا پرینت QR را بگیرید'
        return
      }

      if (!this.factorPrinted) {
        this.$errorModal.value = 'لطفا پرینت فاکتور را بگیرید'
        return
      }
      this.confirmProceedModal = true
    },
    invoiceSubmitted() {
      this.confirmProceedModal = false
      this.$router.replace({name: 'InventoryPackerInvoiceList'})
    },
    invoiceRejected() {
      this.rejectModal = false
      this.$router.replace({name: 'InventoryPackerInvoiceList'})
    },
    printInvoice() {
      this.factorPrinted = true
      this.invoicePrintModal = true
      setTimeout(() => window.print(), 50)
    },
    printQR() {
      this.QRPrinted = true
      this.confirmQRModal = false
      this.invoicePrintModal = false
      this.invoiceQRPrintModal = true
      setTimeout(() => window.print(), 50)
    }
  },
}
</script>

<style scoped>

</style>