<template>
  <div class="intro-y flex items-center">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست کارکنان</h1>

    <VButton class="!w-auto mr-auto" @click="userModal = true; editUser = {}">افزودن یا ویرایش کارمند</VButton>
  </div>

  <div class="intro-y grid grid-cols-2 lg:grid-cols-3 gap-4 mt-4 xl:w-1/2" v-if="showFilter">
    <VInput placeholder="جستجو نام، موبایل" v-model.lazy="this.filter.q" @input="this.filter.page = 1"
            shadow/>

    <Multiselect
        dir="rtl"
        class="border-none shadow"
        v-model="filter.roleId"
        :options="roles"
        @change="this.filter.page = 1"
        :searchable="true"
        placeholder="انتخاب نقش"/>

    <Multiselect
        dir="rtl"
        class="border-none shadow"
        v-model="filter.isActive"
        :options="states"
        @change="this.filter.page = 1"
        :searchable="true"
        placeholder="وضعیت"/>
  </div>
  <!-- Pagination -->
  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="users.PageCount"
      :range-size="1"/>

  <VCard class="!bg-transparent intro-y mt-4 px-2 text-slate-600" noPadding scrollable>
    <div ref="table"></div>
  </VCard>

  <CreateUserModal
      v-model="userModal"
      :editUser="editUser"
      @created="userCreated"
      @updated="userUpdated"
      :type="type"/>
</template>

<script>
import VCard from "@/components/General/VCard";
import VPagination from "@hennge/vue3-pagination";
import {getUsers} from "@/API/API.js";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import CreateUserModal from "@/components/Modal/CreateUserModal";
import VButton from "@/components/General/VButton";
import VInput from "@/components/General/VInput";
import Multiselect from "@vueform/multiselect";

export default {
  name: "UserListTable",
  props: ['type'],
  components: {VInput, VButton, CreateUserModal, VCard, VPagination, Multiselect},
  data() {
    return {
      users: [],
      roles: [],
      states: [],
      userModal: false,
      editUser: {},
      showFilter: true,

      tabulator: null,

      firstCall: 1,
      filter: {
        q: '',
        page: 1,
        selectedInventory: '',
        roleId: ''
      },
    }
  },
  created() {
    this.getUsers()
    this.states = [
      {label: 'فعال', value: true},
      {label: 'غیرفعال', value: false},
    ]
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      let editButtons = document.querySelectorAll("div[data-btn-type='edit']")
      editButtons.forEach(element => {
        element.onclick = () => {
          this.editUser = this.users.Users.find(user => user.UserId == element.dataset.id)
          this.userModal = true
        }
      })
    });
  },
  methods: {
    async getUsers() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.users = (await getUsers(this.filter, this.firstCall)).data.data

      if (this.users.Roles.length > 0)
        this.roles = this.users.Roles.map(role => ({
          label: role.Name,
          value: role.RoleId,
        }))

      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.users.Users)
      window.scrollTo(0, 0)
    },
    userCreated(user) {
      this.userModal = false
      this.users.Users.unshift(user)
      this.refreshTable()
    },
    userUpdated(user) {
      this.userModal = false
      let index = this.users.Users.findIndex(item => item.UserId === user.UserId)
      this.users.Users[index] = user
      this.refreshTable()
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'UserId',
            vertAlign: "middle"
          },
          {
            title: 'نام',
            field: 'Name',
            formatter: cell => {
              return `<div class="flex items-center">
                        <img src="${this.$helper.imageURL(cell.getData().Avatar?.Path) }" alt="" class="${cell.getData().Avatar?.Path ? ' w-5 h-10 ' : ''}rounded-full">
                        <span class="whitespace-pre-wrap normal-digits mr-2">${cell.getData().Name}</span>
                      </div>`
            }
          },
          {
            title: 'نام خانوادگی',
            field: 'Family',
            vertAlign: "middle",
          },
          {
            title: 'موبایل',
            field: 'Mobile',
            vertAlign: "middle",
            headerSort: false
          },
          {
            title: 'نقش',
            field: 'Roles',
            vertAlign: "middle",
            headerSort: false,
            variableHeight: true,
            visible: this.type !== 'distributor-manager',
            formatter: (cell) => {
              let template = `<div class="flex text-xs flex-wrap">`
              cell.getValue()?.forEach(role => template += `<span class="badge-outline border-blue-300 m-0.1">${role.Name}</span>`)
              return template + `</div>`
            }
          },
          {
            title: 'انبار',
            field: 'Inventories',
            vertAlign: "middle",
            headerSort: false,
            variableHeight: true,
            formatter: (cell) => {
              let template = `<div class="flex text-xs flex-wrap">`
              cell.getValue()?.forEach(inventory => template += `<span class="badge-outline border-red-300 m-0.2">${inventory.Name}</span>`)
              return template + `</div>`
            }
          },
          {
            title: 'وضعیت',
            field: 'IsActive',
            vertAlign: "middle",
            headerSort: false,
            variableHeight: true,
            //visible: this.type === 'admin',
            formatter: (cell) => {
              let isActive = cell.getValue()

              return `
                <span class="px-2 py-0.5 text-xs text-white rounded ${isActive ? 'bg-green-500' : 'bg-red-500'}">
                    ${isActive ? 'فعال' : 'غیرفعال'}
                </span>
              `
            }
          },
          {
            title: 'ماشین',
            field: 'DistributorVehicle',
            variableHeight: true,
            vertAlign: "middle",
            visible: this.type === 'distributor-manager',
            formatter: (cell) => {
              let activeVehicle = cell.getData().DistributorVehicle

              if (activeVehicle.VehicleInventoryId) {
                return activeVehicle?.Vehicle?.Type?.Name + ` (${activeVehicle?.Vehicle?.Plaque})`
              }

              return '-'
            }
          },
          {
            title: 'ظرفیت ماشین',
            field: 'DistributorVehicle.BasketCount',
            variableHeight: true,
            vertAlign: "middle",
            visible: this.type === 'distributor-manager',
            formatter: (cell) => (cell.getData().DistributorVehicle?.BasketCount ?? '-') + ' سبد'
          },
          {
            title: 'عملیات',
            field: 'UserId',
            vertAlign: "middle",
            resizable: false,
            headerSort: false,
            formatter: (cell) => {
              return `<div class="flex items-center text-amber-500 cursor-pointer" data-btn-type="edit" data-id="${cell.getValue()}">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                          <span class="mr-1 text-xs">ویرایش</span>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.firstCall = 0
        this.getUsers()
      }
    },
  }
}
</script>

<style scoped>

</style>