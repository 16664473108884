<template>

  <div class="intro-y flex items-center justify-between">
    <h1 class="lg:text-lg cursor-pointer" @click="showFilter = !showFilter">تحویل به مشتری</h1>

    <div class="border border-yellow-500 p-2 rounded-lg">
      وضعیت تخصیص:
      <span v-if="$loading.value">....</span>
      <span v-else>{{ state?.State?.Name ?? '-' }}</span>
    </div>

    <div></div>
  </div>

  <InvoiceListTable type="distributor-delivery" @assignmentState="state = $event"
    @distributorAssignment="distributorAssignment" :show-filter="showFilter" />

</template>

<script>
import {sendLocation} from "@/API/API";
import InvoiceListTable from "@/components/Invoice/InvoiceListTable";

export default {
  name: "InvoiceListDelivery",
  components: { InvoiceListTable },
  emits: ['distributorAssignment'],
  data() {
    return {
      showFilter: true,
      state: {},
      intervalid: ''
    }
  },
  mounted() {
    this.todo();
  },
  unmounted() {
    this.intervalid = ''
  },
  methods: {
    distributorAssignment(id) {
      this.$emit('distributorAssignment', id)
    },
    todo: function () {
      this.intervalid = setInterval(() => {
        if (navigator.geolocation)
      {
          navigator.geolocation.getCurrentPosition((position) => {
            sendLocation({lat:position.coords.latitude, long:position.coords.longitude})
            console.log('latitude', position.coords.latitude);
            console.log('longitude', position.coords.longitude);     
          });
        }
      }, 3000);
    },
  },
}
</script>

<style scoped></style>