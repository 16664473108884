<template>
  <teleport to="body">
    <div id="invoice-print"
    class="absolute inset-0 w-screen hidden bg-white text-[0.6rem] print:block ">
      <VueQrcode :value="String(invoiceId)" :options="{  margin:0, width: 200 }" />
      <div class="font-bold text-2xl text-center">{{ invoiceId }}</div>
    </div>
  </teleport>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  name: "InvoiceQRPrint",
  props: ['invoiceId'],
  components: { VueQrcode },

}
</script>

<style scoped></style>